<template>
  <footer class="containerFooter bookFont">
    <p class="containerFooter__Text">©{{ currentYear }} Grey Group. A WPP Company.</p>
  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'peopleFooter',
  computed: {
    ...mapState({
      currentYear: state => state.currentYear
    })
  }
}
</script>
