import { createStore } from 'vuex'
import Axios from 'axios'
import * as types from './mutationTypes'
import VueJwtDecode from 'vue-jwt-decode'
import defineAbilitiesFor from './abilities'
import filtersModule from './filtersModule'

const state = {
  newSearch: false,
  selectedCards: [],
  clickSelectAllFirst: false,
  modelSearchBox: '',
  searchKeyword: '',
  dataUserLogin: {},
  isMobile: true,
  isMobileResolution: false,
  isListViewMobile: false,
  isGridViewListView: false,
  isPhone: true,
  isToolbarVertical: true,
  results: [],
  query: {},
  totalResultsCount: 0,
  firstTime: true,
  randomOffset: 0,
  favorite: {
    lists: [],
    profiles: {}
  },
  currentYear: new Date().getFullYear(),
  collections: [],
  resultsPage: {
    showButton: false
  },
  newSpell: '',
  newSpellNumber: null,
  correctionNewSpell: '',
  pagination: {
    page: 1,
    size: 48
  },
  loading: {
    app: true
  },
  routeConfig: {},
  navigation: {
    scrollPosition: 0,
    appHeigth: 0,
    fromDetailView: false
  },
  isGridView: true,
  maintenanceMode: false,
  originalResults: false,
  initialOffset: 0,
  cardFields: [
    'id', 'thumbnail_url_cdn', 'thumbnail_url', 'title', 'persona_title', 'agency', 'country', 'phone', 'email', 'linkedin', 'mobile_phone',
    'city', 'locations', 'business_region', 'discipline', 'employee_manager', 'employee_manager_employeecode', 'description', 'recent_experience',
    'past_experience', 'superpower', 'categories', 'industry', 'skills', 'client', 'past_clients', 'awards', 'other_awards', 'languages', 'start_date', 'instagram',
    'twitter', 'facebook', 'hobbies_interests', 'midsize_picture', 'tags', 'brand', 'profile_percentage'
  ].join(', '),
  idsLoaded: false,
  people: {
    metadata: null,
    profileCategories: [],
    profileClients: []
  },
  sectionEdited: '',
  formIsTouched: false,
  file: {},
  originalProfilePicture: {},
  profileData: null,
  savingProfile: false,
  approvalFields: [],
  managerInfo: {},
  collectionsMine: [],
  collectionsCardsId: [],
  collectionsInfo: {
    isCollectionModalOpened: false
  },
  threeDotsMenuItem: '',
  walkthroughs: {
    PPRO_profileEdit: {
      hasBeenCompleted: true,
      isOnTheRightRoute: false,
      isReadyToRender: false,
      onlyForDesktop: true,
      currentStep: 0
    },
    PPRO_myPortfolio: {
      hasBeenCompleted: true,
      isOnTheRightRoute: false,
      isReadyToRender: false,
      onlyForDesktop: false
    }
  },
  dragAndDropVisible: false,
  dragAndDropEnabled: false,
  renderView: false,
  profileTabActive: 'about',
  selectedSort: {
    title: 'Newest',
    sort: 'newest'
  },
  abilities: null,
  showFooter: true,
  base64SrcImage: null,
  isFixedheader: false
}

const getters = {
  mobileState: state => state.isMobile,
  phoneState: state => state.isPhone,
  toolbarVerticalState: state => state.isToolbarVertical,

  /**
    * @description Indicates if current user logged in is the same as a given ecCode.
    * @param {ecCode} string with ecCode to verify.
    */
  isUserLogged: (state) => (ecCode) => {
    const ecCodeUser = state.dataUserLogin.ecCode
    if (ecCodeUser) {
      if (ecCode) {
        return ecCodeUser.toLowerCase() === ecCode.toLowerCase()
      }
      return state.profileData ? ecCodeUser.toLowerCase() === state.profileData.id.toLowerCase() : false
    }
    return false
  },

  /**
    * @description Indicates if a given walkthrough should be shown or not.
    * @param {walkthroughName} name of the walkthrough.
    */
  isWalkthroughVisible: (state, getters) => (walkthroughName) => {
    const currentWalkthrough = state.walkthroughs[walkthroughName]
    /* eslint-disable */
    const isRightResolution = currentWalkthrough.onlyForDesktop ? state.isMobile ? false : true : true
    /* eslint-enable */
    return !currentWalkthrough.hasBeenCompleted && currentWalkthrough.isOnTheRightRoute && isRightResolution && getters.isUserLogged
  },

  /**
   * @description Validate if an action can be performed by the current user
   * @param action Action to be performed
   * @param resource Resource of the app
   */
  actionCanBePerformed: (state) => (action, resource) => {
    return state.abilities.can(action, resource)
  },

  /**
  * @description Indicates if current user logged in is the same as a given email
  * @param email email to verify.
  */
  isUserLoggedEmail: (state) => (email) => {
    return state.dataUserLogin.email === email
  },

  /**
  * @description Indicates the iOS Version of the device
  */
  iOSVersion: (state) => () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return userAgent.includes('iphone') && Number(userAgent.substr(userAgent.indexOf('version/') + 8, 2))
  }
}

const mutations = {
  /**
   * @description Validate if user is authenticated to render components
   * @param {*} state of store
   * @param {payload} renderView flag
   */
  [types.CAN_RENDER_VIEW] (state, payload) {
    state.renderView = payload.renderView
  },

  /**
   * @description Save user data login
   * @param {state} state of store.
   */
  [types.DATA_USER_LOGIN] (state) {
    const personalData = VueJwtDecode.decode(localStorage.getItem('sessionToken'))
    state.dataUserLogin.email = personalData.email
    state.dataUserLogin.name = personalData.unique_name
    state.dataUserLogin.ecCode = personalData.eccode
    state.dataUserLogin.isAuthenticated = true
    state.dataUserLogin.role = personalData.role
    state.dataUserLogin.countryIso2Code = personalData.country_iso2_code
    state.abilities = defineAbilitiesFor(state.dataUserLogin.role)
  },

  /**
   * @description Toggles saving profile flag to notify saving process has started/finished.
   * @param {state} state of store.
   * @param {payload} savingProfile boolean to indicate if process has started/finished.
   */
  [types.TOGGLE_SAVING_PROFILE_STATE] (state, payload) {
    state.savingProfile = payload.savingProfile
  },

  /**
   * @description Sets profile data information.
   * @param {state} state of store.
   * @param {payload} profileData object with information of people profile.
   */
  [types.SET_PROFILE_DATA] (state, payload) {
    state.profileData = payload.profileData
    state.profileData.categories.sort()
    state.profileData.skills.sort()
    state.profileData.awards.sort()
    state.profileData.languages.sort()
    state.profileData.client.sort()
  },

  /**
   * @description Updates some properties of profile data information.
   * @param {state} state of store.
   * @param {payload} fields object with properties to be updated of profile information.
   */
  [types.UPDATE_PROFILE_DATA] (state, payload) {
    Object.keys(payload.fields).forEach((field) => {
      state.profileData[field] = payload.fields[field]
    })
    state.profileData = Object.assign({}, state.profileData)
  },

  /**
   * @description Updates profile avatar image.
   * @param {state} state of store.
   * @param {payload} image string with the new image url
   */
  [types.UPDATE_PROFILE_PICTURE] (state, payload) {
    state.profileData.thumbnail_url = payload.image
    state.profileData.midsize_picture = payload.image
  },

  [types.SET_SECTION_EDITED] (state, payload) {
    state.sectionEdited = payload.sectionEdited
  },

  /**
   * @description Sets profile form as touched because of the user modifying the values
   * @param {state} state of store
   * @param {payload} flag  Value to set to the state
   */
  [types.SET_FORM_TOUCHED] (state, payload) {
    state.formIsTouched = payload.formIsTouched
  },

  [types.IS_MOBILE] (state, isMobile) {
    state.isMobile = isMobile
  },

  [types.IS_TOOLBAR_VERTICAL] (state, isToolbarVertical) {
    state.isToolbarVertical = isToolbarVertical
  },

  [types.SET_RESULTS] (state, Payload) {
    state.results = Payload.results ? Payload.results : []
  },

  /**
   * @description Obtain all Ids when select all has been checked.
   *              It create an array with Ids on groups as well
   * @event NOTE Only execute one time while user doesn't make a new search (Performance)
   */
  [types.OBTAIN_ID_USERS] (state) {
    this.commit({
      type: 'SET_IDS_LOADED',
      idsLoaded: false
    })
    state.loading.app = false
    const iterations = Math.ceil(state.totalResultsCount / 1000)
    if (!Number.isInteger(iterations)) {
      return
    }
    state.clickSelectAllFirst = !state.clickSelectAllFirst
    state.selectedCards = []

    if (iterations < 1) {
      this.commit({
        type: 'SET_IDS_LOADED',
        idsLoaded: true
      })
      state.loading.app = false
      return
    }

    let currentIteration = 1
    const interval = setInterval(() => {
      const iteration = currentIteration
      Axios({
        method: 'POST',
        url: process.env.VUE_APP_NEW_SEARCH_API_URL,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
        data: {
          ...state.query,
          size: 1000,
          page: currentIteration,
          source: 'persona',
          returnFields: ['id']
        }
      }).then((response) => {
        if (response.data.value) {
          response.data.value.forEach((item) => state.selectedCards.push(item.id))
        }

        if (iteration === iterations) {
          this.commit({
            type: 'SET_IDS_LOADED',
            idsLoaded: true
          })
          state.loading.app = false
        }
      }).catch((error) => {
        console.error(error)
      })
      if (currentIteration === iterations) {
        clearInterval(interval)
      }
      currentIteration++
    }, 300)
  },

  [types.SET_QUERY] (state, query) {
    state.query = query
  },

  [types.SET_TOTAL_RESULTS_COUNT] (state, count) {
    state.totalResultsCount = count
  },

  [types.CLEAR_STORE] (state) {
    state.results = []
    state.query = []
    state.queryNoChipValue = ''
    state.totalResultsCount = 0
  },

  /**
   * @description Check if the user is logged since any desktop or mobile
   */
  [types.CHECK_IS_MOBILE] (state) {
    state.isMobile = navigator.userAgent.match(/Android|iPhone|iPad/i)
  },
  /**
   * @description Check if the user is logged since any phone
   */
  [types.CHECK_IS_PHONE] (state) {
    state.isPhone = navigator.userAgent.match(/iPhone/i) ? navigator.userAgent.match(/iPhone/i) : (navigator.userAgent.match(/Android/i) ? (navigator.userAgent.match(/Android/i) && window.innerWidth < 552) : window.innerWidth < 552)
  },
  /**
   * @description Check if list view is on mobile version
   */
  [types.CHECK_IS_LISTVIEW_MOBILE] (state) {
    state.isListViewMobile = window.innerWidth < 930
  },
  /**
   * @description Check if grid view must be displayed on grid view(apply for phones)
   */
  [types.CHECK_IS_GRIDVIEW_LISTVIEW] (state) {
    state.isGridViewListView = window.innerWidth < 488
  },

  /**
   * @description Set the new search value to the store
   * @param {Payload} searchValue the new search value
   */
  [types.SET_SEARCH_VALUE] (state, Payload) {
    state.modelSearchBox = Payload.searchValue
    if (Payload.apply) {
      state.searchKeyword = Payload.searchValue
    }
  },

  /**
   * @description Set the newSpell value to the store
   * @param {Payload} newSpell the new value
   */
  [types.SET_NEW_SPELL] (state, Payload) {
    state.newSpell = Payload.newSpell
  },

  /**
   * @description Set the newSpellNumber value to the store
   * @param {Payload} newSpellNumber the new value
   */
  [types.SET_NEW_SPELL_NUMBER] (state, Payload) {
    state.newSpellNumber = Payload.newSpellNumber
  },

  /**
   * @description Set the correctionNewSpell value to the store
   * @param {Payload} searchValue the new value
   */
  [types.SET_CORRECTION_NEW_SPELL] (state, Payload) {
    state.correctionNewSpell = Payload.correctionNewSpell
  },

  /**
   * @description Clear search
   */
  [types.CLEAR_SEARCH] (state) {
    state.modelSearchBox = ''
    state.searchKeyword = ''
  },

  /**
   * @description Store input option list for the filter modal
   */
  [types.SET_FILTER_DATA] (state, payload) {
    const filterOptionsData = {
      business_region: {
        count: 0, values: [], displayName: 'Region'
      },
      country: {
        count: 0, values: [], displayName: 'Country'
      },
      city: {
        count: 0, values: [], displayName: 'City'
      },
      discipline: {
        count: 0, values: [], displayName: 'Discipline'
      },
      categories: {
        count: 0, values: [], displayName: 'Industry'
      },
      client: {
        count: 0, values: [], displayName: 'Client'
      },
      agency: {
        count: 0, values: [], displayName: 'Agency'
      },
      persona_title: {
        count: 0, values: [], displayName: 'Title'
      },
      skills: {
        count: 0, values: [], displayName: 'Skills'
      }
    }
    Object.keys(filterOptionsData).forEach(category => {
      actions.dispatch({
        type: 'setCategoryFilters',
        filters: payload.filters,
        categoryName: category,
        displayName: filterOptionsData[category].displayName,
        filterOptionsData: filterOptionsData
      })
    })
  },

  /**
   * @description Add new items to a specific collection
   */
  [types.ADD_ITEMS_TO_COLLECTION] (state, payload) {
    const { id, items } = payload
    state.collections.forEach(collection => {
      if (collection.id === id) {
        items.forEach(item => collection.itemIds.push(item))
      }
    })
  },

  /**
   * @description Remove items from a specific collection
   */
  [types.REMOVE_ITEMS_FROM_COLLECTION] (state, payload) {
    const { id, items } = payload
    state.collections.forEach(collection => {
      if (collection.id === id) {
        collection.itemIds = collection.itemIds.filter(item => !items.includes(item))
      }
    })
  },

  /**
   * @description Updates store flag that indicates if user is using a mobile resolution.
   * @param {state} state of store.
   */
  [types.TOGGLE_MOBILE_RESOLUTION] (state) {
    state.isMobileResolution = window.innerWidth <= 698
  },

  /**
   * @description Set the values for the result page
   * @param {payload} bottom Boolean to see if we are at the bottom of page
   * @param {payload} showButton Boolean to see if the result scroll to top button should be displayed
   */
  [types.RESULTS_PAGE_SETTINGS] (state, payload) {
    state.resultsPage.showButton = payload.showButton
  },

  /**
   * @description Set number of page and size
   * @param {payLoad} size number of results
   * @param {payLoad} page number of page
   */
  [types.SET_PAGINATION] (state, payLoad) {
    state.pagination.size = payLoad.size || state.pagination.size
    state.pagination.page = payLoad.page || state.pagination.page
  },

  /**
   * @description Clear properties and reset
   */
  [types.CLEAR_PROPERTIES] (state) {
    state.pagination.page = 1
  },

  /**
   * @description Set loading app flag
   */
  [types.SET_LOADING_APP] (state, Payload) {
    state.loading.app = Payload.loading
  },

  /**
   * @description Set randomOffset value
   */
  [types.SET_RANDOM_OFFSET] (state, Payload) {
    state.randomOffset = Payload.randomOffset
  },

  /**
   * @description Set routeConfig value
   */
  [types.SET_ROUTE_CONFIG] (state, Payload) {
    const routeConfig = {
      name: 'resultsPage',
      query: {}
    }
    if (Payload.searchTerm) {
      routeConfig.query.q = Payload.searchTerm
      if (Payload.searchTerm.indexOf('%22') !== -1) {
        routeConfig.query.s = state.correctionNewSpell
      }
    }
    if (state.filters.url) {
      routeConfig.query.f = state.filters.url
    }
    routeConfig.query.p = Payload.pageNumber
    routeConfig.query.gv = state.isGridView
    state.routeConfig = routeConfig
  },
  /**
   * @description Set the current home scroll position
   * @param {payload} scrollPosition get the current scrollTop
   * @param {payload} appHeigth get the current height from #app element
   * @param {payload} fromDetailView is flag for determinate when use the stored scroll positoin
   */
  [types.SET_SCROLL_POSITION] (state, payload) {
    state.navigation.scrollPosition = payload.scrollPosition
    state.navigation.appHeigth = payload.appHeigth
    state.navigation.fromDetailView = payload.fromDetailView
  },
  /**
   * @description Set the state isGridView
   * @param {payload} isGridView new boolean value to set the state
   */
  [types.SET_GRID_VIEW] (state, payload) {
    state.isGridView = payload.isGridView
  },
  /**
   * @description Set the maintenance mode
   */
  [types.SHOW_MAINTENANCE_MODE] (state) {
    state.maintenanceMode = true
  },
  /**
   * @description Set originalResults state
   * @param {payload} originalResults new boolean value to orignalResults
   */
  [types.SET_ORIGINAL_RESULTS] (state, payload) {
    state.originalResults = payload.originalResults
  },

  /**
   * @description Sets initial value of offset get randomly.
   * @param {state} state of store app.
   * @param {payload} initialOffset initial offset got randomly.
   */
  [types.SET_INITIAL_OFFSET] (state, payload) {
    state.initialOffset = payload.initialOffset
  },

  /**
   * @description Change state flag
   * @param {state} state of store.
   * @param {payload} idsLoaded True or false
   */
  [types.SET_IDS_LOADED] (state, payload) {
    state.idsLoaded = payload.idsLoaded
  },

  /**
   * @description Sets the metadata to populate people form.
   * @param {state} state of store
   * @param {payload} metadata metadata info retrieved from API.
   */
  [types.SET_PEOPLE_METADATA] (state, payload) {
    state.people.metadata = payload.metadata
  },

  /**
   * @description Sets the categories related to the users profile.
   * @param {state} state of store
   * @param {payload} metadata metadata info retrieved from API.
   * @param {payload} userCategories categories of the user.
   */
  [types.SET_PROFILE_CATEGORIES] (state, payload) {
    const industriesSelected = {}

    payload.userCategories.forEach(industryCategory => {
      const industrySplitted = industryCategory.split(' : ')
      industriesSelected[industrySplitted[0]] = industriesSelected[industrySplitted[0]] || {}
      industriesSelected[industrySplitted[0]][industrySplitted[1]] = {}
    })

    state.people.profileCategories = payload.metadata.industries.map(industry => {
      let counterSelection = 0
      const industryTree = {
        label: industry.value,
        labelSearch: industry.value.toLowerCase().trim(),
        isSelected: Boolean(industriesSelected[industry.value]),
        isVisible: true,
        isIndeterminate: false,
        children: industry.categories.map((category) => {
          const isCategorySelected = Boolean(industriesSelected[industry.value] && industriesSelected[industry.value][category.value])
          if (isCategorySelected) {
            counterSelection++
          }
          return {
            label: category.value,
            isSelected: isCategorySelected,
            isVisible: true,
            isIndeterminate: false
          }
        })
      }
      industryTree.isIndeterminate = industryTree.children && industryTree.children.length && counterSelection ? industryTree.children.length !== counterSelection : false
      return industryTree
    })
  },

  /**
   * @description Sets the clients related to the users profile.
   * @param {state} state of store
   * @param {payload} metadata metadata info retrieved from API.
   * @param {payload} userClients clients of the user.
   */
  [types.SET_PROFILE_CLIENTS] (state, payload) {
    const clientSelected = {}
    payload.userClients.forEach(preloadClient => {
      const hasColonSeparator = preloadClient.indexOf(' : ') >= 0
      const hasSlashSeparator = preloadClient.indexOf('/') >= 0
      if (hasColonSeparator || hasSlashSeparator) {
        const clientSplitted = hasColonSeparator ? preloadClient.split(' : ') : preloadClient.split('/')
        clientSelected[clientSplitted[0].trim()] = clientSelected[clientSplitted[0].trim()] || {}
        clientSelected[clientSplitted[0].trim()][clientSplitted[1] ? clientSplitted[1].trim() : undefined] = {}
      }
    })

    state.people.profileClients = payload.metadata.clients.map(client => {
      let counterSelection = 0
      let isClientSelected = false
      const clientTree = {
        label: client.value,
        labelSearch: client.value.toLowerCase().trim(),
        isVisible: true,
        isIndeterminate: false,
        children: client.brands.map((brand) => {
          const isBrandSelected = Boolean(clientSelected[client.value] && clientSelected[client.value][brand.value])
          if (isBrandSelected) {
            counterSelection++
            isClientSelected = true
          }
          return {
            label: brand.value,
            isSelected: isBrandSelected,
            isVisible: true,
            isIndeterminate: false
          }
        }),
        isSelected: isClientSelected
      }
      clientTree.isIndeterminate = clientTree.children && clientTree.children.length && counterSelection ? clientTree.children.length !== counterSelection : false
      return clientTree
    })
  },

  /**
   * @description Save globaly file uploaded.
   * @param {state} state of store
   * @param {payload} file uploaded
   */
  [types.SAVE_UPLOAD_FILE] (state, payload) {
    state.file = payload.file
  },

  /**
   * @description Save globaly file uploaded.
   * @param {state} state of store
   * @param {payload} file uploaded
   */
  [types.SET_ORIGINAL_PROFILE_IMAGE] (state, payload) {
    state.originalProfilePicture = payload.originalProfilePicture
  },

  /**
   * @description Set manager Info
   * @param {state} state of store
   * @param {payload} managerInfo Array info
   */
  [types.GET_MANAGER_INFO] (state, payload) {
    state.managerInfo = payload.managerInfo
  },

  /**
   * @description set field waiting for approval.
   * @param {state} state of store
   * @param {payload} approvalFields
   */
  [types.SET_APPROVAL_FIELDS] (state, payload) {
    state.approvalFields = payload.approvalFields
  },

  /**
   *
   * @description Set colecction in array of state
   * @param {state} state of store
   * @param {payload} collectionsMine Array where are colecctions
   * @param {payload} cardIdToAdd Id card to verify if It belongs to some collection and set attribute
   */
  [types.SET_COLLECTIONS_MINE] (state, payload) {
    state.collectionsMine = []
    payload.collectionsMine.forEach((value) => {
      let containId = false
      if (payload.cardsIdToAdd.length === 1) {
        containId = value.itemIds.includes(payload.cardsIdToAdd[0])
      } else {
        containId = !payload.cardsIdToAdd.some((id) => !value.itemIds.includes(id))
      }
      state.collectionsMine.push(Object.assign({ containId: containId }, value))
    })
  },

  /**
   *
   * @description Set config about collections
   * @param {state} state of store
   * @param {payload} itemsId IDs from cards
   */
  [types.SET_COLLECTION_INFO] (state, payload) {
    state.collectionsInfo.isCollectionModalOpened = payload.flag
    state.collectionsCardsId = payload.itemsId
  },

  /**
   *
   * @description Update reactivity when collection got some change
   * @param {state} state of store
   * @param {payload} positionCollection Position in array where collection changed its state
   */
  [types.ADD_REMOVE_ITEM_FROM_COLLECTION] (state, payload) {
    state.collectionsMine.forEach(
      (value, index) => {
        if (index === payload.positionCollection) {
          value.containId = !value.containId
          // Vue.set(value, 'containId', !value.containId)
        }
      }
    )
  },

  /**
   * @description Set current 3 dots menu opened
   * @param {*} state of store
   * @param {payload} item Card id
   */
  [types.SET_THREE_DOTS_MENU_STATE_BY_ITEM] (state, payload) {
    state.threeDotsMenuItem = payload.item
  },

  /**
   *
   * @description Toggles a given property of a given walkthrough.
   * @param {state} state of store.
   * @param {payload} walkthroughName name of the walkthrough to be modified.
   * @param {payload} property of the walkthrough to modify.
   * @param {payload} value that will have the property.
   */
  [types.TOGGLE_WALKTHROUGH_VISIBILITY] (state, payload) {
    state.walkthroughs[payload.walkthroughName][payload.property] = payload.value
  },

  /**
   * @description Sets the state of walkthroughs to the initial state.
   * @param {state} state of store.
   */
  [types.SET_INITIAL_WALKTHROUGH_STATUS] (state) {
    state.walkthroughs = {
      PPRO_profileEdit: {
        hasBeenCompleted: true,
        isOnTheRightRoute: false,
        isReadyToRender: false,
        onlyForDesktop: true,
        currentStep: 0
      },
      PPRO_myPortfolio: {
        hasBeenCompleted: true,
        isOnTheRightRoute: false,
        isReadyToRender: false,
        onlyForDesktop: false
      }
    }
  },

  /**
   * @description Check if drag and drop is visible
   * @param state of store
   */
  [types.CHECK_IS_DDTOGGLE_VISIBLE] (state) {
    state.dragAndDropVisible = !this.isMobile && window.innerWidth > 1000
  },

  /**
   * @description set drag and drop enabled
   * @param state of store
   * @param {payload} flag value of the variable
   */
  [types.SET_DRAGDROP_ENABLED] (state, payload) {
    state.dragAndDropEnabled = payload.dragAndDropEnabled
  },

  /**
   * @description set profile tab active
   * @param {state} state of store.
   * @param {payload} profileTabActive Selected profile tab
   */
  [types.SET_PROFILE_TAB_ACTIVE] (state, payload) {
    state.profileTabActive = payload.profileTabActive
  },

  /**
   * @description set recovery info to show on self-profile
   * @param {state} state of store.
   * @param {payload} recoveryInfo Phone and email data to recover password
   */
  [types.SET_RECOVERY_INFO] (state, payload) {
    state.recoveryInfo = payload.recoveryInfo
  },

  /**
   * @description set selected sort
   * @param {state} state of store.
   * @param {payload} selectedSort Selected sort on my uploads tab
   */
  [types.SET_SELECTED_SORT] (state, payload) {
    state.selectedSort = payload.selectedSort
  },

  /**
   * @description Sets if the footer is visible or not
   * @param {state} state of store.
   * @param {payload} flag
   */
  [types.SET_FOOTER_VISIBILITY] (state, payload) {
    state.showFooter = payload.flag
  },

  /**
   * @description Set base64 image src containing cropped image
   * @param {*} state of store
   * @param {payload} base64SrcImage base 64 src
   */
  [types.SET_BASE64_SRC_IMAGE] (state, payload) {
    state.base64SrcImage = payload.base64SrcImage
  },

  /**
   * @description Sets if the header is is fixed or not
   * @param {*} state of store
   * @param {payload} flag fixed header status
   */
  [types.IS_FIXED_HEADER] (state, payload) {
    state.isFixedheader = payload.flag
  }
}

const actions = {
  onResize ({ commit }, value) {
    commit(types.IS_MOBILE, value)
  },

  onResizeToolbar ({ commit }, value) {
    commit(types.IS_TOOLBAR_VERTICAL, value)
  },

  setQuery ({ commit }, value) {
    commit(types.SET_QUERY, value)
  },

  setTotalResultsCount ({ commit }, value) {
    commit(types.SET_TOTAL_RESULTS_COUNT, value)
  },

  clearStore ({ commit }, value) {
    commit(types.CLEAR_STORE, value)
  },

  saveIdsUsers ({ commit }) {
    commit(types.OBTAIN_ID_USERS)
  },

  /**
   * @description Service to retrieve the suggestions word when user type.
   */
  predictiveSearch: function (context, Payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_SUGGESTIONS_SERVICE}Suggestions?filterExpression=source eq 'persona'&top=6&term=${Payload.searchTerm}&fuzzy=false`
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Service to add the current user in the sharedwith list of the collection.
   * @param Payload Payload
   * @param {Payload} sharedLink link shared with the user
   * @param {Payload} userEmail email of the logged user
   */
  addUserToSharedWithList: function (context, Payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}Collections/Link/${Payload.sharedLink}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Service to get the filter facets from gpm.
   */
  getFilterFacets (context) {
    context.commit({
      type: 'UPDATE_TEMP_QUERY_FILTER'
    })
    return new Promise((resolve, reject) => {
      const keyword = context.state.newSpell ? context.state.newSpell : context.state.keyWordFilters
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}Filters/PeopleFinder?q=${keyword}&f=${context.state.filters.queryBetweenFilters}`
      }).then(
        (response) => {
          context.commit({
            type: 'FILTERS_LOADING',
            isLoading: false
          })
          context.commit({
            type: 'SET_FILTER_DATA',
            filters: response.data
          })
          context.commit({
            type: 'SET_FILTER_CATEGORY_OPTIONS',
            filters: response.data
          })
          context.commit({
            type: 'GET_FILTERS'
          })
          context.commit({
            type: 'SELECT_CATEGORY_FILTER',
            selectedCategory: context.state.filters.selectedCategory
          })
          context.commit({
            type: 'UPDATE_FINAL_FILTERS',
            selectedFilters: context.state.filters.temporaryFilters
          })
          resolve(context.state.filters.filterList)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Launches a request to the API to retrieve metadata to populate experience form.
   * @param {context} context
   * @param {payload} payload
   */
  getPeopleMetadata (context, payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}profile/metadata/`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then(
        (response) => {
          context.commit({
            type: 'SET_PEOPLE_METADATA',
            metadata: response.data
          })

          if (payload.updateCategories) {
            context.commit({
              type: 'SET_PROFILE_CATEGORIES',
              metadata: response.data,
              userCategories: context.state.profileData.categories
            })
          }

          if (payload.updateClients) {
            context.commit({
              type: 'SET_PROFILE_CLIENTS',
              metadata: response.data,
              userClients: context.state.profileData.client
            })
          }
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  /**
   * @description Launches a request to the API to update information of the profile.
   * @param {context} context of store.
   * @param {payload} body with the information to be updated.
   */
  updateProfile (context, payload) {
    context.commit({
      type: 'TOGGLE_SAVING_PROFILE_STATE',
      savingProfile: true
    })
    return new Promise((resolve, reject) => {
      Axios({
        method: 'POST',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}persona/update`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        },
        data: payload.body
      }).then((response) => {
        context.commit({
          type: 'UPDATE_PROFILE_DATA',
          fields: response.data
        })
        context.commit({
          type: 'TOGGLE_SAVING_PROFILE_STATE',
          savingProfile: false
        })
        context.dispatch({
          type: 'getManagerData',
          ecCode: context.state.profileData.employee_manager_employeecode
        })
        resolve(response)
      }, (error) => {
        context.commit({
          type: 'TOGGLE_SAVING_PROFILE_STATE',
          savingProfile: false
        })
        reject(error)
      })
    })
  },
  /**
   * @description Launches a request to the API to update information to recovery password
   * @param {context} context of store.
   * @param {payload} body with the information to be updated.
   */
  saveRecoveryInfo (context, payload) {
    context.commit({
      type: 'TOGGLE_SAVING_PROFILE_STATE',
      savingProfile: true
    })
    return new Promise((resolve, reject) => {
      Axios({
        method: 'POST',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}profile/recoverypassword`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        },
        data: payload.body
      }).then((response) => {
        context.commit({
          type: 'TOGGLE_SAVING_PROFILE_STATE',
          savingProfile: false
        })
        resolve(response)
      }, (error) => {
        context.commit({
          type: 'TOGGLE_SAVING_PROFILE_STATE',
          savingProfile: false
        })
        reject(error)
      })
    })
  },

  /**
   * @description Send to BE the file
   * @param {*} context of store
   * @param {payload}
   */
  uploadImage (context, payload) {
    const data = new FormData()
    data.append('croppedProfilePicture', context.state.file)
    data.append('originalProfilePicture', context.state.originalProfilePicture)
    return new Promise((resolve, reject) => {
      Axios({
        method: 'POST',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}file/upload`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        },
        data: data
      }).then(
        (response) => {
          context.commit({
            type: 'UPDATE_PROFILE_PICTURE',
            image: response.data
          })
          resolve(response)
        },
        (error) => {
          console.error(error)
          reject(error)
        }
      )
    })
  },

  /**
   * @description query pending approval fields.
   * @param {context} context
   * @param {payload} payload
   */
  getApprovalFields (context, payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}profile/approvalFields/${payload.ecCode}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then((response) => {
        context.commit({
          type: 'SET_APPROVAL_FIELDS',
          approvalFields: response.data.approvalFields
        })
        resolve(response)
      },
      (error) => {
        reject(error)
      })
    })
  },
  /**
   * @description query getting profile picture.
   * @param {context} context
   * @param {payload} image profile image url
   */
  getProfilePicture (context, payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}profilepicture/${payload.ecCode}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then((response) => {
        context.commit({
          type: 'UPDATE_PROFILE_PICTURE',
          image: response.data.url
        })
        resolve(response)
      },
      (error) => {
        reject(error)
      })
    })
  },

  /**
   * @description Get manager data
   * @param {*} context of store
   * @param {payload} ecCode of manager
   */
  getManagerData (context, payload) {
    return new Promise((resolve, reject) => {
      context.dispatch({
        type: 'getSearchApiPeople',
        params: {
          returnFields: ['midsize_picture', 'title', 'persona_title', 'email', 'agency', 'business_region'],
          filters: [
            {
              field: 'id',
              values: [payload.ecCode]
            }
          ]
        }
      }).then((response) => {
        context.commit({
          type: 'GET_MANAGER_INFO',
          managerInfo: response.data.value[0]
        })
        resolve(response)
      },
      (error) => {
        console.error(error)
        reject(error)
      })
    })
  },

  /**
   * @description Gets a list of suggestions of user's name based on a given term.
   * @param {context} context of the store.
   * @param {payload} term to get its coincidences.
   */
  getPeopleSuggestions (context, payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_SUGGESTIONS_SERVICE}suggestions/people?term=${payload.term}&top=100`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then(
        (response) => resolve(response.data),
        (error) => reject(error)
      )
    })
  },

  /**
   * @description Process to login
   * @param {payload} ecCode of employee
   * @param {payload} fields needed
   */
  getPortfolioList (context, payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}portfolio/list/${payload.ecCode}?fields=${payload.fields}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then((response) => {
        resolve(response.data)
      },
      (error) => {
        console.error(error)
        reject(error)
      })
    })
  },

  /**
    * @description Get collections mine
    * @param {context} context of the store.
    * @param {payload} id Card id to be added to the collection.
    */
  getCollections (context, payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}Collections/list/`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      }).then((response) => {
        context.commit({
          type: 'SET_COLLECTIONS_MINE',
          collectionsMine: response.data,
          cardsIdToAdd: payload.ids
        })
        resolve(context.state.collectionsMine)
      },
      (error) => {
        console.error(error)
        reject(error)
      })
    })
  },

  /**
    *
    * @description Get pending tours per App.
    * @param {context} context of store.
    * @param {payload} appName name of the app to retrieve its pending tours.
    */
  getPendingTours: function (context, payload) {
    if (!context.getters.isUserLogged()) {
      return
    }
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_GP_SERVICES}Walkthrough/${payload.appName}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then((response) => {
        const pendingWalkthroughs = response.data.pendingWalkthroughs
        if (Array.isArray(pendingWalkthroughs)) {
          pendingWalkthroughs.forEach((pendingWalkthrough) => {
            context.commit({
              type: 'TOGGLE_WALKTHROUGH_VISIBILITY',
              walkthroughName: pendingWalkthrough,
              property: 'hasBeenCompleted',
              value: false
            })
          })
        }
        resolve(response)
      }, (error) => reject(error))
    })
  },

  /**
    *
    * @description Makes a call to API to mark a tour as completed.
    * @param {context} context of store.
    * @param {payload} tourId name of the completed tour.
    */
  markTourAsCompleted: function (context, payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'POST',
        url: `${process.env.VUE_APP_GP_SERVICES}Walkthrough/Complete/${payload.tourId}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then((response) => resolve(response), (error) => reject(error))
    })
  },

  /**
    * @description Deletes an item from my portfolio.
    * @param {context} context of the store.
    * @param {payload} id of the item to remove from portfolio.
    */
  deleteItemFromPortfolio (context, payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'DELETE',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}portfolio/remove/${payload.id}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then((response) => resolve(response), (error) => reject(error))
    })
  },

  /**
    *
    * @description Get preferences for profile
    * @param {context} context of store
    * @param {payload} ecCode eccode of the current user
    */
  getPreferencesByEccode: function (context, payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}profile/defaultview/${payload.ecCode}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then((response) => resolve(response), (error) => reject(error))
    })
  },

  /**
    *
    * @description Get preferences for profile
    * @param {context} context of store
    */
  getPreferences: function (context) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}profile/mysettings/`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then((response) => resolve(response), (error) => reject(error))
    })
  },

  /**
    *
    * @description save preferences for profile
    * @param {context} context of store
    * @param {payload} data defaultView of employee
    */
  savePreferences: function (context, payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'POST',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}profile/mysettings/`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
        data: payload.data
      }).then((response) => resolve(response), (error) => reject(error))
    })
  },

  /**
    * Updates the visibility of walkthroughs based on the tab selected.
    * @param {context} context of the store.
    * @param {payload} activeTab name of the tab active.
    */
  updateActiveTab (context, payload) {
    if (!context.getters.isUserLogged()) {
      return
    }
    const walkthroughs = context.state.walkthroughs
    Object.keys(walkthroughs).forEach((walkthroughName) => {
      let isOnTheRightRoute = false
      switch (walkthroughName) {
        case 'PPRO_profileEdit' :
          isOnTheRightRoute = payload.activeTab === 'about'
          break
        case 'PPRO_myPortfolio' :
          isOnTheRightRoute = payload.activeTab === 'myPortfolio'
          break
      }
      context.commit({
        type: 'TOGGLE_WALKTHROUGH_VISIBILITY',
        walkthroughName: walkthroughName,
        property: 'isOnTheRightRoute',
        value: isOnTheRightRoute
      })
    })
  },

  /**
    * @description Order portfolio items.
    * @param {context} context of the store.
    * @param {payload} items ordered list of items.
    */
  orderPortfolioItems (context, payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'POST',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}portfolio/order`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
        data: payload.items
      }).then((response) => resolve(response), (error) => reject(error))
    })
  },

  /**
    * @description Get my uploads list items.
    * @param {context} context of the store.
    */
  getUploadsList (context) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}uploads/list/${context.state.selectedSort.sort}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then((response) => resolve(response), (error) => reject(error))
    })
  },

  /**
    * @description Get logged user profile information from people service endpoint.
    * @param {context} context of the store.
    */
  getLoggedUserProfileInformation (context, payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_PEOPLE_SERVICE}profile/detail/${payload.ecCode}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then((response) => resolve(response), (error) => reject(error))
    })
  },

  /**
   * @description Allow to use search API
   * @param {payload} params Object parameters to search
   */
  getSearchApiPeople (context, payload) {
    const dataQuery = {
      source: 'persona',
      page: 1,
      size: 48,
      searchMode: 'any',
      returnFields: ['id', 'thumbnail_url_cdn', 'thumbnail_url', 'title', 'persona_title', 'agency', 'country', 'phone', 'email', 'linkedin', 'mobile_phone', 'city', 'locations', 'business_region', 'discipline', 'employee_manager', 'employee_manager_employeecode', 'description', 'recent_experience', 'past_experience', 'superpower', 'categories', 'industry', 'skills', 'client', 'past_clients', 'awards', 'other_awards', 'languages', 'start_date', 'instagram', 'twitter', 'facebook', 'hobbies_interests', 'midsize_picture', 'tags', 'brand', 'profile_percentage'],
      spellCheck: context.state.correctionNewSpell === ''
    }
    for (const prop in payload.params) { dataQuery[prop] = payload.params[prop] }
    return new Promise((resolve, reject) => {
      Axios({
        method: 'POST',
        url: process.env.VUE_APP_NEW_SEARCH_API_URL,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
        data: dataQuery
      }).then(response => resolve(response), error => reject(error))
    })
  },

  /**
   * @description Load random users to show in homePage
   * @param {payload} count Number amount of expected results
   * @param {payload} k Number to generate random results
   */
  getRandomPeople (contex, payload) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'GET',
        url: `${process.env.VUE_APP_SEARCH_API_RANDOM_USERS_URL}${payload.k}/${payload.count}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` }
      }).then(response => resolve(response), error => reject(error))
    })
  },

  /**
   * @description Set vh mobile devices style
   */
  setVhMobileDevices (context) {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vhMobileDevice', `${vh}px`)
  }
}

export default createStore({
  state,
  mutations,
  actions,
  getters,
  modules: {
    filters: filtersModule
  }
})
