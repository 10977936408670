<template>
  <section class="containerGenericWalkthrough">
    <genericOverlay @click="skipWalkthrough" />
    <v-tour :name="name" :steps="steps" :callbacks="myCallbacks" :options="options" v-if="!options.centered">
      <template name="walkthrough">
        <section v-for="(step, index) of walkthrough.steps" :key="index">
          <transition name="hide">
            <v-step
              v-if="walkthrough.currentStep === index"
              :step="step"
              :previous-step="walkthrough.previousStep"
              :next-step="walkthrough.nextStep"
              :stop="walkthrough.stop"
              :is-first="walkthrough.isFirst"
              :is-last="walkthrough.isLast"
              :labels="walkthrough.labels"
              :class="{'centered': options.centered}"
            >
              <div name="actions">
                <div class="v-step__buttons">
                  <span class="v-step__number" v-if="options.showNumbers">{{`${(index + 1)}/3`}}</span>
                  <button
                    @click.prevent="walkthrough.previousStep"
                    v-if="!walkthrough.isFirst"
                    class="v-step__button v-step__button-previous"
                  >BACK</button>
                  <button
                    @click.prevent="walkthrough.nextStep"
                    v-if="!walkthrough.isLast"
                    class="v-step__button v-step__button-next"
                  >NEXT</button>
                  <button
                    @click.prevent="walkthrough.finish"
                    v-if="walkthrough.isLast"
                    class="v-step__button v-step__button-stop"
                  >GOT IT</button>
                </div>
              </div>
            </v-step>
          </transition>
        </section>
      </template>
    </v-tour>
    <section v-if="options.centered" class="containerGenericWalkthrough__centeredWalkthrough">
      <slot name="content"></slot>
      <slot name="buttons" v-bind:methods="{ skipWalkthrough, onStopWalkthrough }"></slot>
    </section>
  </section>
</template>

<script>
import genericOverlay from '@/components/genericOverlay/genericOverlay'
import { useStore } from 'vuex'
import { nextTick, onMounted, reactive, watchEffect } from 'vue'

export default {
  name: 'genericWalkthrough',
  components: {
    genericOverlay
  },
  props: {
    name: {
      type: String,
      required: true
    },
    steps: {
      type: Array
    },
    options: {
      type: Object,
      default: null
    },
    isOverlayVisible: {
      type: Boolean,
      default: true
    },
    isReady: {
      type: Boolean,
      default: true
    }
  },
  emits: ['updateCurrentStep'],
  setup (props, { emit }) {
    const store = useStore()
    const myCallbacks = reactive({
      onFinish: onStopWalkthrough,
      onPreviousStep: updateCurrentStep,
      onNextStep: updateCurrentStep
    })

    onMounted(() => {
      startWalkthrough(props.isReady)
    })

    /**
     * @description Function to be executed on every change of isReady property.
     * @param {isComponentReady} boolean with the last value of isReady property.
     */
    watchEffect(() => props.isReady, (isComponentReady) => {
      startWalkthrough(isComponentReady)
    })

    /**
     * @description Emits an event to notify to its parent component that there is a new current step.
     */
    function updateCurrentStep () {
      nextTick(() => {
        emit('updateCurrentStep', this.$tours[props.name].currentStep)
      })
    }

    /**
     * @description Callback when the user press GOT IT button.
     */
    function onStopWalkthrough () {
      hideWalkthrough()
      store.dispatch({
        type: 'markTourAsCompleted',
        tourId: props.name
      })
    }

    /**
     * @description Callback when the user clicks on the overlay.
     */
    function skipWalkthrough () {
      props.options.centered || this.$tours[props.name].skip()
      hideWalkthrough()
    }

    /**
     * @description Hides the walkthrough only to UI level.
     */
    function hideWalkthrough () {
      store.commit({
        type: 'TOGGLE_WALKTHROUGH_VISIBILITY',
        walkthroughName: props.name,
        property: 'hasBeenCompleted',
        value: true
      })
    }

    /**
     * @description Starts the walkthrough if the flag send it is true.
     * @param {isReady} boolean to know is toru can be started.
     */
    function startWalkthrough (isReady) {
      if (isReady) {
        props.options.centered || this.$tours[props.name].start()
      }
    }

    return {
      store,
      myCallbacks,
      skipWalkthrough
    }
  }
}
</script>
