export const IS_MOBILE = 'IS_MOBILE'
export const IS_TOOLBAR_VERTICAL = 'IS_TOOLBAR_VERTICAL'
export const SET_RESULTS = 'SET_RESULTS'
export const SET_QUERY = 'SET_QUERY'
export const ADD_QUERY_VALUE = 'ADD_QUERY_VALUE'
export const REMOVE_QUERY_VALUE = 'REMOVE_QUERY_VALUE'
export const UPDATE_QUERY_NO_CHIP_VALUE = 'UPDATE_QUERY_NO_CHIP_VALUE'
export const SET_TOTAL_RESULTS_COUNT = 'SET_TOTAL_RESULTS_COUNT'
export const CLEAR_STORE = 'CLEAR_STORE'
export const OBTAIN_ID_USERS = 'OBTAIN_ID_USERS'
export const CHECK_IS_MOBILE = 'CHECK_IS_MOBILE'
export const CHECK_IS_LISTVIEW_MOBILE = 'CHECK_IS_LISTVIEW_MOBILE'
export const CHECK_IS_GRIDVIEW_LISTVIEW = 'CHECK_IS_GRIDVIEW_LISTVIEW'
export const CHECK_IS_PHONE = 'CHECK_IS_PHONE'
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
export const SET_FILTER_DATA = 'SET_FILTER_DATA'
export const GET_FILTERS = 'GET_FILTERS'
export const SELECT_CATEGORY_FILTER = 'SELECT_CATEGORY_FILTER'
export const SET_COLLECTIONS = 'SET_COLLECTIONS'
export const ADD_ITEMS_TO_COLLECTION = 'ADD_ITEMS_TO_COLLECTION'
export const REMOVE_ITEMS_FROM_COLLECTION = 'REMOVE_ITEMS_FROM_COLLECTION'
export const TOGGLE_MOBILE_RESOLUTION = 'TOGGLE_MOBILE_RESOLUTION'
export const RESULTS_PAGE_SETTINGS = 'RESULTS_PAGE_SETTINGS'
export const UPDATE_TEMP_QUERY_FILTER = 'UPDATE_TEMP_QUERY_FILTER'
export const SET_NEW_SPELL = 'SET_NEW_SPELL'
export const SET_NEW_SPELL_NUMBER = 'SET_NEW_SPELL_NUMBER'
export const SET_CORRECTION_NEW_SPELL = 'SET_CORRECTION_NEW_SPELL'
export const CLEAR_PROPERTIES = 'CLEAR_PROPERTIES'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_LOADING_APP = 'SET_LOADING_APP'
export const SET_RANDOM_OFFSET = 'SET_RANDOM_OFFSET'
export const SET_ROUTE_CONFIG = 'SET_ROUTE_CONFIG'
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION'
export const SET_GRID_VIEW = 'SET_GRID_VIEW'
export const SHOW_MAINTENANCE_MODE = 'SHOW_MAINTENANCE_MODE'
export const SET_ORIGINAL_RESULTS = 'SET_ORIGINAL_RESULTS'
export const SET_INITIAL_OFFSET = 'SET_INITIAL_OFFSET'
export const SET_IDS_LOADED = 'SET_IDS_LOADED'
export const SET_PEOPLE_METADATA = 'SET_PEOPLE_METADATA'
export const SET_SECTION_EDITED = 'SET_SECTION_EDITED'
export const SET_FORM_TOUCHED = 'SET_FORM_TOUCHED'
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA'
export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA'
export const UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE'
export const SAVE_UPLOAD_FILE = 'SAVE_UPLOAD_FILE'
export const TOGGLE_SAVING_PROFILE_STATE = 'TOGGLE_SAVING_PROFILE_STATE'
export const SET_APPROVAL_FIELDS = 'SET_APPROVAL_FIELDS'
export const GET_MANAGER_INFO = 'GET_MANAGER_INFO'
export const SET_PROFILE_CATEGORIES = 'SET_PROFILE_CATEGORIES'
export const SET_PROFILE_CLIENTS = 'SET_PROFILE_CLIENTS'
export const SET_COLLECTIONS_MINE = 'SET_COLLECTIONS_MINE'
export const SET_COLLECTION_INFO = 'SET_COLLECTION_INFO'
export const ADD_REMOVE_ITEM_FROM_COLLECTION = 'ADD_REMOVE_ITEM_FROM_COLLECTION'
export const SET_THREE_DOTS_MENU_STATE_BY_ITEM = 'SET_THREE_DOTS_MENU_STATE_BY_ITEM'
export const TOGGLE_WALKTHROUGH_VISIBILITY = 'TOGGLE_WALKTHROUGH_VISIBILITY'
export const SET_INITIAL_WALKTHROUGH_STATUS = 'SET_INITIAL_WALKTHROUGH_STATUS'
export const CHECK_IS_DDTOGGLE_VISIBLE = 'CHECK_IS_DDTOGGLE_VISIBLE'
export const SET_DRAGDROP_ENABLED = 'SET_DRAGDROP_ENABLED'
export const DATA_USER_LOGIN = 'DATA_USER_LOGIN'
export const CAN_RENDER_VIEW = 'CAN_RENDER_VIEW'
export const SET_PROFILE_TAB_ACTIVE = 'SET_PROFILE_TAB_ACTIVE'
export const SET_RECOVERY_INFO = 'SET_RECOVERY_INFO'
export const SET_SELECTED_SORT = 'SET_SELECTED_SORT'
export const RESET_FILTERS = 'RESET_FILTERS'
export const FILTERS_LOADING = 'FILTERS_LOADING'
export const UPDATE_QUERY = 'UPDATE_QUERY'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_FILTERS_SELECTED_BEFORE = 'SET_FILTERS_SELECTED_BEFORE'
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY'
export const TOGGLE_FILTER_SELECTION = 'TOGGLE_FILTER_SELECTION'
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS'
export const COPY_FILTERS = 'COPY_FILTERS'
export const SET_FILTERS_FROM_URL = 'SET_FILTERS_FROM_URL'
export const UPDATE_URL = 'UPDATE_URL'
export const SET_FOOTER_VISIBILITY = 'SET_FOOTER_VISIBILITY'
export const SET_BASE64_SRC_IMAGE = 'SET_BASE64_SRC_IMAGE'
export const IS_FIXED_HEADER = 'IS_FIXED_HEADER'
export const SET_RANDOM_FILTER_CATEGORY_TAB_KEY = 'SET_RANDOM_FILTER_CATEGORY_TAB_KEY'
export const SET_ORIGINAL_PROFILE_IMAGE = 'SET_ORIGINAL_PROFILE_IMAGE'
