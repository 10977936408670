<template>
  <div id="containerApp" :class="{'marginTool': !isToolbarHorizontal}" v-on:scroll="handleScroll">
    <interceptors :axios="axiosInstance"></interceptors>
    <toolbar
      :user="store.state.dataUserLogin.name"
      app="pf"
      :ecCode="store.state.dataUserLogin.ecCode"
      :email="store.state.dataUserLogin.email"
      :role="store.state.dataUserLogin.role"
      v-if="store.state.dataUserLogin.isAuthenticated"
      ref="toolbarComponentRef"
    >
    </toolbar>
    <principalWalkthrough v-if="store.getters.isUserLogged()"/>
    <maintenance v-if="store.state.maintenanceMode" linkTitle="PEOPLE OF GREY" />
    <router-view v-else :key="$route.params.id" @update-image="updateImage" />
    <toast v-if="shareToast" :class="{'marginToolToast': !isToolbarHorizontal}"
      message="A new shared collection was added. To see it go to Collections in the toolbar.">
    </toast>
    <peopleFooter v-if="showFooter"></peopleFooter>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import axios from 'axios'
import { init } from './registerWorker'
import principalWalkthrough from './components/principalWalkthrough/principalWalkthrough'
import peopleFooter from '@/components/peopleFooter/PeopleFooter'
import { onMounted, onUpdated, ref, computed } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'App',
  components: {
    principalWalkthrough,
    peopleFooter
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const isToolbarHorizontal = ref(null)
    const shareToast = ref(null)
    const toolbarComponentRef = ref(null)
    const axiosInstance = axios

    onMounted(() => {
      setUp()
      resizeSetUp()
      handleResize()
      toolbarWorkFlow()

      // Force clean from previous versions
      localStorage.removeItem('inputOptions')
      store.dispatch('onResize', isMobileScreen())
    })

    onUpdated(() => {
      sessionStorage.clear()
    })

    function isMobileScreen () {
      return window.matchMedia('screen and (max-width: 736px)').matches
    }

    /**
     * @description calls function from toolbar.vue to refresh profile picture when a new picture is uploaded
     */
    function updateImage () {
      toolbarComponentRef.value.refreshImage(store.state.profileData.thumbnail_url)
    }

    /**
     * @description Handler to know the size of the screen
     */
    function handleResize () {
      window.addEventListener('resize', function (e) {
        resizeSetUp()
        const isMobile = isMobileScreen()
        if (isMobile !== store.state.isMobile) {
          store.dispatch('onResize', isMobile)
        }
      })
    }

    /**
    * @description Handler to know the size of the screen
    */
    function resizeSetUp () {
      store.commit({
        type: 'CHECK_IS_MOBILE'
      })
      store.commit({
        type: 'CHECK_IS_PHONE'
      })
      store.commit({
        type: 'CHECK_IS_LISTVIEW_MOBILE'
      })
      store.commit({
        type: 'CHECK_IS_GRIDVIEW_LISTVIEW'
      })
      store.commit({
        type: 'CHECK_IS_DDTOGGLE_VISIBLE'
      })
      store.commit({
        type: 'TOGGLE_MOBILE_RESOLUTION'
      })
      store.dispatch('setVhMobileDevices')
      setTimeout(() => {
        if (store.state.isMobile || window.innerWidth < 1024) {
          isToolbarHorizontal.value = true
        } else {
          isToolbarHorizontal.value = false
        }
        store.dispatch('onResizeToolbar', !isToolbarHorizontal.value)
      })
    }

    /**
      * @description Method to control the behavior(open/close) of the filters container
      */
    function toolbarWorkFlow () {
      window.addEventListener('scroll', () => {
        if (document.getElementById('toolMobile')) {
          if ((window.scrollY === 0) && (!document.getElementById('mainMobile'))) {
            document.getElementById('toolMobile').classList.add('colorToolMobile')
          } else {
            document.getElementById('toolMobile').classList.remove('colorToolMobile')
          }
        }
      })
    }

    /**
      * @description Check for changes on page scroll.
      */
    function handleScroll (e) {
      store.commit({
        type: 'RESULTS_PAGE_SETTINGS',
        showButton: arrowVisible(e.target)
      })
    }

    /**
      * @description Check if the scroll to top buttom should be displayed
      * @param element that is being scrolled
      */
    function arrowVisible (element) {
      if (window.screen.width > 1135) {
        return false
      }
      const scrollY = element.scrollTop
      const showButton = scrollY >= 200
      return showButton
    }

    /**
      * @description Run app when it inits
      */
    function setUp () {
      store.commit({
        type: 'DATA_USER_LOGIN'
      })
      store.commit({
        type: 'CAN_RENDER_VIEW',
        renderView: true
      })
      init(localStorage.getItem('sessionToken'))
    }

    /**
     * @description Indicate if footer is visible or not
    */
    const showFooter = computed(() => {
      return store.state.dataUserLogin.isAuthenticated && store.state.showFooter
    })

    return {
      store,
      route,
      isToolbarHorizontal,
      shareToast,
      axiosInstance,
      handleScroll,
      showFooter,
      toolbarComponentRef,
      updateImage
    }
  }
}
</script>

<style src="./styles/style.scss" lang="scss"></style>
