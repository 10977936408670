<template>
  <section class="containerPrincipalWalkthrough">
    <genericWalkthrough
      v-if="store.getters.isWalkthroughVisible('PPRO_profileEdit')"
      name="PPRO_profileEdit"
      :steps="configuration['PPRO_profileEdit'].steps"
      :options="configuration['PPRO_profileEdit'].myOptions"
      :isReady="store.state.walkthroughs.PPRO_profileEdit.isReadyToRender"
      @updateCurrentStep="updateCurrentStep($event, 'PPRO_profileEdit')"
    />
    <genericWalkthrough
      v-if="store.getters.isWalkthroughVisible('PPRO_myPortfolio')"
      name="PPRO_myPortfolio"
      class="containerPrincipalWalkthrough__containerCentered"
      :options="configuration['PPRO_myPortfolio'].myOptions"
      :isReady="store.state.walkthroughs.PPRO_myPortfolio.isReadyToRender"
    >
      <template v-slot:content>
        <h1 class="containerPrincipalWalkthrough__containerCentered--centeredHeader">
          Welcome to your Portfolio!
        </h1>
        <p class="containerPrincipalWalkthrough__containerCentered--centeredContent">
          Here you can show all the <strong>files</strong> and <strong>articles</strong> you have worked on.
        </p>
        <p class="containerPrincipalWalkthrough__containerCentered--centeredContent marginExtra">
          To do this, <strong>search</strong> the content you want to add, find the <strong>three dots</strong> icon and choose <strong>"add to my portfolio"</strong>.
        </p>
        <img class="containerPrincipalWalkthrough__containerCentered--image" :src="require('@/assets/walkthrough_portfolio.gif')"/>
      </template>
      <template v-slot:buttons="scopedMethods">
        <section class="containerPrincipalWalkthrough__containerCentered--buttons">
          <button class="cancelButton" @click="scopedMethods.methods.skipWalkthrough">Remind me later</button>
          <button class="finishButton" @click="scopedMethods.methods.onStopWalkthrough">GOT IT</button>
        </section>
      </template>
    </genericWalkthrough>
  </section>
</template>

<script>
import configurationMessages from './principalWalkthrough.json'
import genericWalkthrough from '@/components/genericWalkthrough/genericWalkthrough'
import { useStore } from 'vuex'
import { onMounted } from 'vue'

export default {
  name: 'principalWalkthrough',
  components: {
    genericWalkthrough
  },
  setup (props) {
    const store = useStore()
    const configuration = configurationMessages

    onMounted(() => {
      store.dispatch({
        type: 'getPendingTours',
        appName: 'profile'
      })
    })

    /**
     * @description Updates the number of the current step of a given walkthorugh.
     * @param {currentStep} number of the current step.
     * @param {walkthroughName} string witht the name of the walkthorugh to update.
     */
    function updateCurrentStep (currentStep, walkthroughName) {
      store.commit({
        type: 'TOGGLE_WALKTHROUGH_VISIBILITY',
        walkthroughName: walkthroughName,
        property: 'currentStep',
        value: currentStep
      })
    }

    return {
      store,
      updateCurrentStep,
      configuration
    }
  }
}
</script>
