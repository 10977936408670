import { AbilityBuilder, Ability } from '@casl/ability'

const defineAbilitiesFor = (role) => {
  const { can, build } = new AbilityBuilder(Ability)

  if (role.includes('Internal')) {
    can('edit', 'profileInformation')
    can('edit', 'profilePicture')
    can('edit', 'profileSettings')
    can('read', 'myPortfolio')
    can('read', 'myUploads')
  }

  return build()
}

export default defineAbilitiesFor
