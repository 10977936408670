import { createRouter, createWebHistory } from 'vue-router'
import VueJwtDecode from 'vue-jwt-decode'
import { trackRouter } from 'vue-gtag-next'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../pages/Home'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (to.query.gv) {
        store.commit({
          type: 'SET_GRID_VIEW',
          isGridView: to.query.gv === 'true'
        })
      }
      next()
    }
  },
  {
    path: '/resultsPage',
    name: 'resultsPage',
    component: () => import('../pages/resultsPage/resultsPage'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (to.query.gv) {
        store.commit({
          type: 'SET_GRID_VIEW',
          isGridView: to.query.gv === 'true'
        })
      }
      next()
    }
  },
  {
    path: '/profile/:id',
    name: 'profile',
    component: () => import('../pages/profile/profile'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length === 0) {
        next()
      } else {
        store.commit({
          type: 'SET_PROFILE_TAB_ACTIVE',
          profileTabActive: 'myPortfolio'
        })
        next(`/profile/${to.params.id}`)
      }
    }
  },
  {
    path: '/myUploads',
    name: 'myUploads',
    component: () => import('../pages/myUploads/myUploads'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters.actionCanBePerformed('read', 'myUploads')) next()
      else next({ name: 'notFound' })
    }
  },
  {
    path: '/Collections/:sharedLink',
    name: 'Collection',
    component: () => import('../pages/Home'),
    meta: { requiresAuth: true }
  },
  {
    path: '/notFound',
    name: 'notFound',
    alias: ['/:catchAll(.*)'],
    component: () => import('../pages/pageNotFound/pageNotFound')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

trackRouter(router)

/**
 * @description Validate if token is valid always when the user get ino any route view
 */
router.beforeEach((to, from, next) => {
  const LOGIN_BASE_URL = process.env.VUE_APP_LOGIN_SERVICE_URL
  if (to.query.pageToken) {
    localStorage.setItem('sessionToken', to.query.pageToken)
    next(vm => {})
    window.location.href = `${window.location.origin}${window.location.pathname}`
  } else {
    if (!localStorage.getItem('sessionToken')) {
      window.location.href = `${LOGIN_BASE_URL}login?site=${window.location.origin}&state=${window.location.pathname}`
      localStorage.setItem('sessionToken', 'null')
    } else {
      let jwtDecode = ''
      const timeNow = Date.now().valueOf() / 1000
      localStorage.setItem('sessionToken', to.query.token ? to.query.token : localStorage.getItem('sessionToken'))
      jwtDecode = VueJwtDecode.decode(localStorage.getItem('sessionToken'))
      if (!jwtDecode || jwtDecode.exp < timeNow) {
        localStorage.clear()
        localStorage.setItem('sessionToken', 'null')
        window.location.href = `${LOGIN_BASE_URL}login?site=${window.location.origin}&state=${window.location.pathname}`
      } else {
        next(vm => {})
        if (to.query.state !== undefined) {
          window.location.href = `${window.location.origin}${to.query.state}`
        }
      }
    }
  }
})

export default router
