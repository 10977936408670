import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/**
 * Dependences
 */
import gtag from 'vue-gtag-next'
import decodeHtml from 'he'
import externalComponentsFromGrey from 'external-components-from-grey'
import VueClickAway from 'vue3-click-away'
// import VueTour from 'vue-tour'

const app = createApp(App)

app.use(store)
app.use(router)

app.use(externalComponentsFromGrey)
if (process.env.VUE_APP_ENABLE_GOOGLE_ANALYTICS === 'true') {
  app.use(gtag, {
    property: {
      id: 'G-KV71QV94BG'
    }
  })
}
app.use(VueClickAway)
// app.use(VueTour)
window.$ = window.jQuery = require('jquery')
app.config.globalProperties.$decodeHtml = decodeHtml

app.config.devtools = true
app.mount('#app')

/**
 * @description before to create the DOM for first time, this function is executed.
 */
//   created () {
//     if (this.$router.history.current.name === 'resultsPage'
//       && JSON.stringify(this.$router.history.current.query) === '{}') {
//         this.$router.push({ name: 'Home' })
//     }
// }
