import Axios from 'axios'
import * as types from './mutationTypes'

const state = {
  selectedCategory: null,
  temporaryFilters: [],
  selectedFilters: [],
  isLoading: false,
  categories: null,
  categoriesAsList: [],
  categorySelected: {},
  query: '',
  url: '',
  randomFilterCategoryTabKey: Math.random()
}

const mutations = {

  /**
   * @description Resets the state of the filters to its initial state.
   * @param {Object} state of filters store.
   */
  [types.RESET_FILTERS] (state) {
    state.selectedCategory = null
    state.temporaryFilters = []
    state.selectedFilters = []
    state.isLoading = true
    state.categories = null
    state.categoriesAsList = []
    state.categorySelected = {}
    state.query = ''
    state.url = ''
  },

  /**
   * @description Sets the value of the flag to know it the filters are loading or not.
   * @param {Object} state of filters store.
   * @param {isLoading} payload flag to know if filters are loading.
   */
  [types.FILTERS_LOADING] (state, payload) {
    state.isLoading = payload.isLoading
  },

  /**
   * @description Sets the query to call the filters based on user selections.
   * @param {Object} state of filters store.
   * @param {getters} payload Reference to store getters
   */
  [types.UPDATE_QUERY] (state, payload) {
    state.query = payload.getters.getQueryFilters(state.temporaryFilters)
  },

  /**
   * @description Sets the value of category object based on the filters got from the endpoint.
   * @param {Object} state of filters store.
   * @param {categories} payload categories got from the endpoint.
   */
  [types.SET_CATEGORIES] (state, payload) {
    state.categoriesAsList = []
    state.categories = {}

    const labels = {
      business_region: 'Region',
      country: 'Country',
      city: 'City',
      discipline: 'Discipline',
      categories: 'Industry',
      client: 'Client',
      agency: 'Agency',
      persona_title: 'Title',
      skills: 'Skills',
      studio: 'Studio',
      start_year: 'Start Year'
    }

    state.categories = payload.categories.reduce((categories, category) => {
      const categoryTemp = {
        name: category.name,
        count: category.count,
        label: labels[category.name] || category.name,
        isSelected: false,
        hasSelectedItems: false,
        filters: category.values.map(filter => ({ ...filter, isSelected: false, category: category.name }))
      }

      categories[category.name] = categoryTemp
      state.categoriesAsList.push(categoryTemp)
      return categories
    }, {})
  },

  /**
   * @description Marks as selected the filters previously selected by the user.
   * @param {Object} state of filters store.
   */
  [types.SET_FILTERS_SELECTED_BEFORE] (state) {
    state.temporaryFilters.forEach(filter => {
      const categoryTemp = state.categories[filter.category]
      categoryTemp.hasSelectedItems = true

      categoryTemp.filters.forEach(filterTemp => {
        if (filterTemp.name === filter.name) {
          filterTemp.isSelected = true
        }
      })
    })
  },

  /**
   * @description Marks a given category as selected.
   * @param {Object} state of filters store.
   * @param {categoryName} payload name of the category to be marked as selected.
   */
  [types.SET_SELECTED_CATEGORY] (state, payload) {
    const categoryName = payload.categoryName || state.categoriesAsList[0].name

    state.categoriesAsList.forEach(category => {
      category.isSelected = category.name === categoryName
    })

    state.categorySelected = state.categoriesAsList.find(category => category.isSelected)
  },

  /**
   * @description Toggles the selection of a given filter.
   * @param {Object} state of filters store.
   * @param {filter} payload filter to be changed.
   * @param {isSelected} payload flag to know if the filter will be selected or not.
   */
  [types.TOGGLE_FILTER_SELECTION] (state, payload) {
    const filterList = payload.isTemporaryFilter ? 'temporaryFilters' : 'selectedFilters'

    if (payload.isSelected) {
      state[filterList].push(payload.filter)
    } else {
      state[filterList] = state[filterList].filter(filter => payload.filter.category !== filter.category || payload.filter.name !== filter.name)
    }

    if (state.categories) {
      state.categories[payload.filter.category].filters.forEach((filter, index) => {
        if (filter.name === payload.filter.name) {
          // Vue.set(state.categories[payload.filter.category].filters, index, Object.assign({}, filter, { isSelected: payload.isSelected }))
        }
      })
    }
  },

  /**
   * @description Clears all the filter from an specified array.
   * @param {Object} state of filters store.
   * @param {isTemporaryFilter} payload flag to know which of the filters (temporary or selected) will be cleared.
   */
  [types.CLEAR_ALL_FILTERS] (state, payload) {
    if (payload.isTemporaryFilter) {
      state.temporaryFilters = []
    } else {
      state.selectedFilters = []
    }
  },

  /**
   * @description Copies an array of filter into another.
   * @param {Object} state of filters store.
   * @param {destiny} payload destiny array.
   * @param {origin} payload origin array.
   */
  [types.COPY_FILTERS] (state, payload) {
    state[payload.destiny] = state[payload.origin].slice(0)
  },

  /**
   * @description Gets the filter from a given url and add them to selected filters array..
   * @param {Object} state of filters store.
   * @param {filtersUrl} payload url with the filters.
   */
  [types.SET_FILTERS_FROM_URL] (state, payload) {
    const filtersFromUrl = payload.filtersUrl.split('…')
    filtersFromUrl.forEach(filter => {
      const filterSplitted = filter.split('|')
      const category = filterSplitted[0]
      const name = filterSplitted[1]

      state.selectedFilters.push({
        category: category,
        name: name,
        isSelected: true
      })
    })
  },

  /**
   * @description Creates the part of the filter for an url based on the filters selected.
   * @param {Object} state of filters store.
   */
  [types.UPDATE_URL] (state) {
    state.url = state.selectedFilters.reduce((filterQuery, filter, key) => {
      filterQuery += `${filter.category}|${filter.name}`
      if (key < state.selectedFilters.length - 1) {
        filterQuery += '…'
      }
      return filterQuery
    }, '')
  },

  /**
   * @description Sets randomFilterCategoryTabKey global variable
   * @param {*} state of store
   */
  [types.SET_RANDOM_FILTER_CATEGORY_TAB_KEY] (state) {
    state.randomFilterCategoryTabKey = Math.random()
  }
}

const actions = {

  /**
   * @description Gets the filters from filter's endpoint.
   * @param {Object} context context of the store.
   * @param {detectLoading} payload flag to know if the user wants to know if the request has been completed.
   * @param {selectedCategory} payload category that will be marked as selected.
   */
  getFilters (context, payload) {
    if (payload.detectLoading) {
      context.commit({
        type: 'FILTERS_LOADING',
        isLoading: true
      })
    }

    context.commit({
      type: 'UPDATE_QUERY',
      getters: context.getters
    })
    const searchWord = context.rootState.newSpell || context.rootState.modelSearchBox
    return new Promise((resolve, reject) => {
      Axios({
        method: 'POST',
        url: `${process.env.VUE_APP_GP_SERVICES}Filters/PeopleFinder`,
        data: {
          query: searchWord,
          filters: context.state.query
        }
      }).then((response) => {
        context.commit({
          type: 'SET_CATEGORIES',
          categories: response.data
        })

        context.commit({
          type: 'SET_SELECTED_CATEGORY',
          categoryName: payload.selectedCategory
        })

        context.commit({
          type: 'SET_FILTERS_SELECTED_BEFORE'
        })

        context.commit({
          type: 'SET_RANDOM_FILTER_CATEGORY_TAB_KEY'
        })

        if (payload.detectLoading) {
          context.commit({
            type: 'FILTERS_LOADING',
            isLoading: false
          })
        }

        resolve()
      },
      (error) => {
        reject(error)
      })
    })
  }
}

const getters = {
  /**
   * @description Gets the query to filter the results based on the filters selected.
   * @param {Object} state of the store.
   * @param {Object} filters as object
   */
  getQueryFilters: (state) => (filters = state.selectedFilters) => {
    const extractValuesByCategory = (category) => {
      return filters.reduce((obj, filter) => {
        if (filter.category === category) { obj.push(filter.name) }
        return obj
      }, [])
    }

    const createArrayFilters = (selectedFilters) => {
      return selectedFilters.reduce((obj, filter) => {
        if (!obj.includes(filter.category)) { obj.push(filter.category) }
        return obj
      }, [])
    }

    const createFiltersObject = (allCategories) => {
      return allCategories.map(category => {
        const result = {
          field: category,
          values: extractValuesByCategory(category)
        }
        return result
      })
    }

    const createFullFilters = filters => createFiltersObject((createArrayFilters(filters)))
    const finalFilters = createFullFilters(filters)
    return finalFilters
  }
}

export default {
  namespaced: true,
  state: () => (state),
  mutations: mutations,
  actions: actions,
  getters: getters
}
