<template>
  <section class="containerGenericOverlay" @click="clickHandler()"></section>
</template>

<script>
export default {
  name: 'genericOverlay',
  emits: ['click'],
  setup (props, { emit }) {
    /**
     * @description Emits a click event to component parent.
     */
    function clickHandler () {
      emit('click')
    }

    return {
      clickHandler
    }
  }
}
</script>
